<template>
      <div class="faq-area black-bg pt-120 pb-90" 
      :style="{ backgroundImage:'url(' + require('../../assets/img/pattern/dot.png') + ')', }"
      data-background="assets/img/pattern/dot.png">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="sec-wrapper mb-30">
                        <h5>Frequently Asked Questions</h5>
                        <h2 class="section-title text-white">Declarative</h2>
                        <p>Delivering quality Cloud Architecture, Transformation, Security and Business services across Aotearoa's public and private sectors</p>
                        <div class="ab-btn mt-30">
                           <router-link to="/contact" class="tp-btn">Learn More</router-link>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              How can we help your business?
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>Declarative provides a full range of services that will deliver business value, ensure security and provide a modern user experience.  We have proven roadmaps, frameworks and architecture that provide known outcomes and accelerate ROI. Start with guidance, or bring in our core team of experts to augment your internal capabilities.</p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Why Declarative?
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>Our team has vast experience across both private enterprise and public sector secure sites and have established a reputation for delivering trusted, modern guidance and business outcomes and cloud solutions that work. </p>
                            </div>
                          </div>
                        </div>
                         <!-- <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Let’s find an office near you?
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                           <h2 class="accordion-header" id="headingThree1">
                             <button  class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                               Binifox WordPress theme for business?
                             </button>
                           </h2>
                           <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                             <div class="accordion-body">
                               <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                             </div>
                           </div> 
                         </div> -->
                      </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>


export default {
    name:'HomeFaq'
}
</script>
