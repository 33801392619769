<template>
      <div class="main-services black-bg pt-120 pb-90" 
      :style="{backgroundImage:'url('+ require('../../assets/img/pattern/pt1.png')+')',}" 
      data-background="assets/img/pattern/pt1.png">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>Consulting Services</h5>
                        <h2 class="section-title text-white">What We Do.</h2>
                     </div>
                  </div>
               </div>

               <div class="row text-center">
                  <div v-for="service in serviceData" :key="service.id" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30">
                     <div class="mservices fix">
                        <div class="ms-line-shape">
                           <div class="ms-line"></div>
                           <div class="ms-line2"></div>
                        </div>
                        <div class="mservices__icon">
                           <i :class="`${service.icon}`"></i>
                        </div>
                        <div class="mservices__text">
                           <h3 class="ms-title">
                              <router-link to="/services-details">{{service.title}}</router-link>
                           </h3>
                        </div>
                     </div>
                  </div>
              
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'HomeServices',
    data () {
       return {
          serviceData:[
             
             {
                id:"1",
                icon:'flaticon-web-optimization',
                title:'CLOUD STRATEGY'
             },
             {
                id:"7",
                icon:'flaticon-develop',
                title:'CLOUD ARCHITECTURE'
             }, 
             {
                id:"2",
                icon:'flaticon-graphic-design',
                title:'CLOUD IDENTITY & ACCESS'
             },
             
             {
                id:"6",
                icon:'flaticon-online-business',
                title:'CLOUD SECURITY & ZERO TRUST'
             }, 
             {
                id:"3",
                icon:'flaticon-app-development',
                title:'MODERN DEVICE MANAGEMENT '
             },
             {
                id:"4",
                icon:'flaticon-copywriter',
                title:'MODERN WORKPLACE'
             },
             {
                id:"8",
                icon:'flaticon-solution',
                title:'ROADMAPS & FRAMEWORKS'
             },
             
             {
                id:"5",
                icon:'flaticon-resource',
                title:'GOVERNANCE & COMPLIANCE'
             }, 
          ]
       }
    }
}
</script>