<template>
        <section class="service-detals pt-120 pb-100 fix">
            <div class="container">
               <h3 class="service-title">Our Services</h3>
               <div class="row">
                  <div class="col-xxl-8 col-xl-8 col-lg-8">
                     
                        <div class="develop-wrapper">
                           
                           <div class="develop-thumb">
                              <img src="../../assets/img/service/service-img-5.jpg" alt="">
                           </div>
                           <div class="develop-content">
                              
                              <p>.</p>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                                 <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Cloud Architecture</h4>
                                       <p>Cloud Architecture, Design and Solutions</p>
                                       <ul>
                                          <li>Understand Business needs and value</li>
                                          <li>Link Enterprise Architecture and Cloud</li>
                                          <li>Communicate complex Cloud services and integrations</li>
                                          <li>Subject matter experts</li>
                                          <li>Proven patterns and outcomes</li>
                                          <li>Leverage industry knowledge and relationships</li>
                                          <li>Architect, design and deliver solutions</li>
                                       </ul>
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-6.jpg" alt="">
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>Architecture isnt about isn't about endless documentation or idealism. We align your business needs with technology, show you exactly what decisions are important and help communicate "Cloud" back to your business.    </p>
                                    </div>
                              </div>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-7.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Cloud Security and Zero Trust</h4>
                                       <p>Identity & Data centric security</p>
                                       <ul>
                                          <li>Conditional Access</li>
                                          <li>Modern Authentication</li>
                                          <li>Microsoft Defender Suite</li>
                                          <li>Microsoft Sentinel</li>
                                          <li>Data Loss Prevention</li>
                                          <li>Azure Policy</li>
                                          <li>RBAC Frameworks</li>
                                       </ul>
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>Done right, the Cloud can significatly improve your security and compliance posture. Data loss prevention capabilities, extended detection and response, AI and improved monitoring and visibility mean that Cloud isn't an on-premises equivalent - it can do it better. We show you how you can leverage these capabilities, and turn security into a conerstone of your business. With increased security comes, assurace and an ability to deliver services and value faster.</p>
                                    </div>
                              </div>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                                                           <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Modern Device Management</h4>
                                       <p>Intune, Microsoft Endpoint Manager, Mobile Devices</p>
                                       <ul>
                                          <li>Cloud-native Device Management</li>
                                          <li>Hybrid device mangement</li>
                                          <li>Device security and posture management</li>
                                          <li>Microsoft, CIS, NCSC and NIST security frameworks</li>
                                          <li>Implement Zero Trust with Device State and compliance</li>
                                          <li>Evergreen fleet management</li>
                                          <li>Autopilot and build services</li>
                                       </ul>
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-8.jpg" alt="">
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>    </p>
                                    </div>
                              </div>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-9.jpg" alt="">
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Modern Workplace</h4>
                                       <p>Teams, SharePoint/OneDrive, M365 Productivity</p>
                                       <ul>
                                          <li>Microsoft Teams - VTC, Collab, Workspaces</li>
                                          <li>M365 Productivity Suite</li>
                                          <li>Office Readiness reports</li>
                                          <li>Modern Collab with SharePoint and OneDrive</li>
                                          <li>Advanced sharing controls</li>
                                          <li>Microsoft Viva</li>
                                          <li>B2B Federation, Guest access and management controls</li>
                                       </ul>
                                    </div>
                              </div>

                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p></p>
                                    </div>
                              </div>
                           </div>
                        </div>

                        <div class="planning-wrapper pt-50">
                           <div class="row">
                                                           <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-content">
                                       <h4>Cloud Governance and Compliance</h4>
                                       <p>Cloud Governance, Continuous Compliance</p>
                                       <ul>
                                          <li>Cloud Governance Frameworks</li>
                                          <li>Cloud Adoption Framework</li>
                                          <li>Well Architected Framework</li>
                                          <li>NZISM, CIS and Microsoft Baseline Compliance</li>
                                          <li>Continuous Complaince - Secure Score and Compliance Modules</li>
                                          <li>M365 DSC Configuration Drift and Remediation</li>
                                       </ul>
                                    </div>
                              </div>
                              <div class="col-xxl-6 col-xl-6 col-lg-6">
                                    <div class="plan-thumb mb-md-40 mb-xs-40">
                                       <img src="../../assets/img/service/service-img-10.jpg" alt="">
                                    </div>
                              </div>
                           <!--
                              <div class="col-xxl-12">
                                    <div class="plan-content pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
                                       <p>   </p>
                                    </div>
                              </div> -->
                           </div>
                        </div> 
                        <!--
                        <div class="choose-right aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                           <div class="accordion" id="accordionExample">
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                          How to process the funtion for development?
                                       </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>There are many variations of passages of available but the majority have suffered alteration in that some form by injected randomised words which don’t look even as slightly believable.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          Where should I incorporate my business?
                                       </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>Web fonts are often terrible for web performance and none of the font loading strategies are particularly effective to address that. Upcoming font options may finally deliver on the promise.</p>
                                       </div>
                                    </div>
                              </div>
                              <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          How there are many variations of passages
                                       </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                                       <div class="accordion-body">
                                          <p>Front-end and design are remarkably complex these days. That’s why we invite kind, smart folks from the community to run online workshops for all of us to learn together. And we have new ones.</p>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div> -->
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4">
                        <div class="sidebar-wrap">
                           <div class="widget_categories grey-bg">
                              <h4 class="bs-widget-title pl-20">Categories</h4>
                              <ul>
                                    <li><router-link to="/services-details" target="_Cloud Architecture">Cloud Architecture</router-link></li>
                                    <li><router-link to="/services-details" target="_blank">Cloud Strategy</router-link></li>
                                    <li><router-link to="/services-details" target="_blank">Cloud Security & Zero Trust</router-link></li>
                                    <li><router-link to="/services-details" target="_blank">Modern Device Management</router-link></li>
                                    <li><router-link to="/services-details" target="_blank">Modern Workplace</router-link></li>
                                    <li><router-link to="/services-details" target="_blank">Governance and Compliance</router-link></li>
                              </ul>
                           </div>
                           <div class="widget-btn mt-30">
                              <router-link to="/contact" class="tp-btn w-100">Contact Us</router-link>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'ServicesDetailsArea'
}
</script>