<template>
      <div class="faq-area pos-rel black-bg" >
        <div class="faq-bg" :style="{backgroundImage:'url(' + require('../../assets/img/bg/faq-1.jpg') + ')'}" ></div>
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 offset-xl-6 col-lg-6 offset-lg-6">
                     <div class="faq-content pl-70 pt-120 pb-120">
                        <div class="sec-wrapper mb-30">
                           <h5>Quality</h5>
                           <h2 class="section-title text-white">Services that deliver</h2>
                        </div>
                        <div class="accordion" id="accordionExample">
                           <div class="accordion-item">
                           <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 How can we help your business?
                              </button>
                           </h2>
                           <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Declarative provides a full range of services that will deliver business value, ensure security and provide a modern user experience.  We have proven roadmaps, frameworks and architecture that provide known outcomes and accelerate ROI. Start with guidance, or bring in our core team of experts to augment your internal capabilities. </p>
                              </div>
                           </div>
                           </div>
                           <div class="accordion-item">
                           <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 Why Declarative?
                              </button>
                           </h2>
                           <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Our team has vast experience across both private enterprise and public sector secure sites and have established a reputation for delivering trusted, modern guidance and business outcomes and cloud solutions that work. </p>
                              </div>
                           </div>
                           </div>
                           <div class="accordion-item">
                           <h2 class="accordion-header" id="headingThree">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 Whats different about us?
                              </button>
                           </h2>
                           <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Declarative puts quality and business value first. We don't clip the ticket, or give you underqualified resources. We care about the same outcomes you do, and put people alongside you with proven experience. We go on the journey with you. </p>
                              </div>
                           </div>
                           </div>
                           <div class="accordion-item">
                              <h2 class="accordion-header" id="headingThree1">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                 Why should I go cloud?
                              </button>
                              </h2>
                              <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Because you're probably already in the Cloud! Cloud covers a wide range of things - from private datacenters to SaaS services. Done right, Cloud can greatly increase security, productivity and deliver enormous business value. We're here to ensure you get the best ROI, and get the value and services your business needs.  </p>
                              </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
   name:'HomeTwoFaq'
}
</script>