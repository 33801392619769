<template>
     <div class="main-services grey-bg pt-120 pb-90" :style="{backgroundImage:'url(' + require('../../assets/img/pattern/pt1.png') +')'}">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>What We Do</h5>
                        <h2 class="section-title">Our Services.</h2>
                     </div>
                  </div>
               </div>
               <div class="row text-center">
                  <div v-for="service in servicesData" :key="service.id" class="col-xl-4 col-lg-4 col-md-6 mb-30">
                     <div class="mfbox mfbox-white">
                        <div class="mf-shape"></div>
                        <div class="mfbox__icon mb-15">
                           <i :class="service.icon"></i>
                        </div>
                        <div class="mfbox__text">
                           <h3 class="mf-title" v-html="service.title"></h3>
                           <p>{{ service.info }}</p>   
                        </div>
                        <div class="mf-btn">
                           <router-link class="squire-btn" to="/services-details">
                              <i class="fal fa-angle-right"></i>
                           </router-link>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'ServicesArea',
    data () {
      return {
         servicesData:[
            {
               id:'1',
               icon:'flaticon-insight',
               title:'Consulting Services',
               info:'Strategy & Roadmaps, Cloud Architecture & Design, Thought Leadership '
            },
            {
               id:'2',
               icon:'flaticon-remarketing',
               title:'Security Services',
               info:'Cloud Security Configuration, Security Reviews, Identity & Access Management'
            },
            {
               id:'3',
               icon:'flaticon-technical-support',
               title:'Modern Device Services',
               info:'Intune Device Management & Modern Workplace, Endpoint Detection and Response'
            },
           /* {
               id:'4',
               icon:'flaticon-technical-support',
               title:'Frameworks and Governance',
               info:'Best practice roadmaps and guidance'
            }, */
         ]
      }
   }
}
</script>