<template>
     <section class="contact-area pt-120 pb-80 fix">
         <div class="container">
             <div class="row">
                 <div class="col-xxl-5 col-xl-6 col-lg-6">
                     <div class="section-title-wrapper mb-15">
                         <h5 class="section-subtitle mb-20">contact us</h5>
                         <h2 class="section-title">Engage us!</h2>
                     </div>
                     <div class="contact-info mr-50 mr-xs-0  mr-md-0">
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fad fa-comments"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>Call Us</span>
                                 <h5><a href="tell:+64276988751">+64 276988751</a></h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-envelope"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>Email Us</span>
                                 <h5><a href="mailto:contact@declarative.nz">contact@declarative.nz</a> </h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-map-marker-alt"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>visit office</span>
                                 <h5><a target="_blank" href="https://www.google.co.nz/maps/place/Wellington/@-41.2933531,174.756426,13.27z/data=!4m5!3m4!1s0x6d38b1fc49e974cb:0xa00ef63a213b470!8m2!3d-41.2923814!4d174.7787463">Wellington, New Zealand</a></h5>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-xxl-7 col-xl-6 col-lg-6">
                     <div class="contact-form">
                         <form action="mailto:contact@declarative.nz" id="contact-form" method="POST">
                             <div class="row">
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="name" type="text" placeholder="Your Name">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="email" type="email" placeholder="Email Address">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="phone" type="text" placeholder="Phone">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="subject" type="text" placeholder="Subject">
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                                     <textarea placeholder="Write Message" name="message"></textarea>
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 mb-20">
                                     <button type="submit" class="tp-btn">Send a message</button>
                                 </div>
                             </div>
                         </form>
                         <p class="ajax-response"></p>
                     </div>
                 </div>
             </div>
         </div>
    </section>
</template>

<script>
export default {
    name:'ContactArea',
}
</script>