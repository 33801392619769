<template>
  <div :class="padd === 'true' ? 'team-area pt-120 pb-0' : 'team-area pt-120 pb-90'">
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Our Team</h5>
            <h2 class="section-title">Cloud Experts.</h2>
          </div>
        </div>
      </div>
      <div class="rows">
        <div class="team-active swiper-container pb-30">
          <swiper
            ref="mySwiper"
            class="pb-50"
            :space-between="25"
            :slides-per-view="2"
            :modules="modules"
            :pagination="{clickable: true}"
            :loop="true"
            :breakpoints="breakpoints"
          >
            <swiper-slide v-for="member in membersData" :key="member.id">
              <div class="team-item swiper-slide">
                <div class="tpteam text-center mb-30">
                  <div class="tpteam__img">
                    <img :src="getImgUrl(member.image)" alt="" />
                    <div class="tpteam__social">
                      <a :href="'https://www.linkedin.com/in/'+ member.linkedin"><i class="fab fa-linkedin"></i></a>
                    </div>
                  </div>
                  <div class="tpteam__text">
                    <h3 class="tpteam-title">
                      <router-link to="/team-details">{{ member.name }}</router-link>
                    </h3>
                    <h5>{{ member.title }}</h5>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <!-- Add Pagination -->
          <div class="swiper-pagination team-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";

export default {
  name: "HomeTeam",
  props:{
    padd:String,
  },
  data() {
    return {
      membersData: [
        {
          id: "1",
          image: "team-member-1.jpg",
          name: "Jonathan Glendenning",
          title: "Principal Cloud Consultant",
          linkedin: "jonathanglendenning",
        },
        {
          id: "2",
          image: "team-member-2.jpg",
          name: "Chris Hannah",
          title: "Senior Cloud Consultant",
          linkedin: "chris-hannah-nz",
        },
       /* {
          id: "3",
          image: "team-member-3.jpg",
          name: "Gregor White",
          title: "Director Sales | Principal Programme Manager",
          linkedin: "gregor-white-043b0914",
        },
        {
          id: "4",
          image: "team-member-6.jpg",
          name: "Ashley Forde",
          title: "Senior Cloud Automation Engineer",
          linkedin: "ashleyforde",
        }, */
      /*  {
          id: "5",
          image: "team-member-8.jpg",
          name: "Hilixa Maria",
        },
        {
          id: "6",
          image: "team-member-9.jpg",
          name: "Philimia Darwin",
        }, */
      ],
      // breakpoints
      breakpoints: {300: { slidesPerView: 1,},
			550: { slidesPerView: 2,},
			768: { slidesPerView: 2,},
			992: { slidesPerView: 2,},
		},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/img/team/" + pic);
    },
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>