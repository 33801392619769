<template>
       <section class="team__details pt-120 pb-160">
         <div class="container">
            <div class="team__details-inner p-relative white-bg">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-img w-img mr-70">
                        <img src="../../assets/img/team/details/team-01.jpg" alt="">
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-content pt-105">
                        <span>Director | Principal Consultant</span>
                        <h3>Jonathan Glendenning</h3>
                        <p> Experienced Cloud Architect/Consultant with extensive Microsoft
365, Microsoft Azure and Security experience. I have led
cloud services design, adoption and security uplift across several NZ Government 
agencies (including secure sites) and private sector entities.</p>
                        <div class="team__details-contact mb-45">
                           <ul>
                              <li >
                                 <div class="icon theme-color ">
                                    <i class="fal fa-envelope"></i>
                                 </div>
                                 <div class="text theme-color ">
                                    <span><a href="mailto:consulting@declarative.nz">consulting@declarative.nz</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon theme-color">
                                    <i class="fas fa-phone-volume"></i>
                                 </div>
                                 <div class="text theme-color">
                                    <span><a href="tel:(+64) 276 988 751">Call</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon">
                                    <i class="fal fa-map-marker-alt"></i>
                                 </div>
                                 <div class="text">
                                    <a target="_blank"
                                       href="">
                                       Wellington,<br> New Zealand</a>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div class="team__details-social theme-social wow fadeInUp" >
                           <ul>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-twitter"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-vimeo-v"></i>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-xl-10 offset-xl-1">
                  <div class="team__details-info mt-60">
                     <h4 >Client & Outcome focused.</h4>
                     <p >Delivering cloud technology services in today’s 
                     world requires designing and integrating 
                     security end-to-end. We can help you do cloud 
                     right, increasing your technology’s business 
                     value while enhancing security and productivity.
                     </p>
                     <p >We know that a lot of cloud and security 
guidance is divorced from the reality of 
implementation and engine-rooms of 
organisations. Declarative brings real-world 
cloud and security knowledge that bridges the 
gap between policy and deployment.</p>

                     <router-link to="/contact" class="tp-btn mt-10" >
                     Book an appointment</router-link>
                  </div>
               </div>
            </div>
            <div><br><br></div>
            <div class="team__details-inner p-relative white-bg">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-img w-img mr-70">
                        <img src="../../assets/img/team/details/team-02.jpg" alt="">
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-content pt-105">
                        <span>Director | Senior Consultant</span>
                        <h3>Chris Hannah</h3>
                        <p>A savvy and seasoned IT professional, with a wealth of knowledge in Microsoft systems. 
                           A focus on building effective relationships with customers and delivery teams has resulted in a proven track record of successful and quality outcomes.
                           I have seen first-hand the market shift toward IaaS, PaaS and SaaS both via Public and Private cloud solutions and this has afforded me a high level of competency in designing, implementing and supporting solutions while understanding key dependencies for clients.</p>
                        <div class="team__details-contact mb-45">
                           <ul>
                              <li >
                                 <div class="icon theme-color ">
                                    <i class="fal fa-envelope"></i>
                                 </div>
                                 <div class="text theme-color ">
                                    <span><a href="mailto:consulting@declarative.nz">consulting@declarative.nz</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon theme-color">
                                    <i class="fas fa-phone-volume"></i>
                                 </div>
                                 <div class="text theme-color">
                                    <span><a href="tel:(+64) 220 431 881">Call</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon">
                                    <i class="fal fa-map-marker-alt"></i>
                                 </div>
                                 <div class="text">
                                    <a target="_blank"
                                       href="">
                                       Wellington,<br> New Zealand</a>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div class="team__details-social theme-social wow fadeInUp" >
                           <ul>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-twitter"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-vimeo-v"></i>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-xl-10 offset-xl-1">
                  <div class="team__details-info mt-60">
                     <h4 >Cloud. Done right.</h4>
                     <p >We are known for our ability to build strong, 
                        trusted relationships, providing guidance and 
                        planning that aligns with your business and 
                        helps deliver your cloud and security outcomes. 
                        We’re here to ensure you gain the most value 
                        from your cloud investment.
                     </p>
                     <p >We know that every organisation has unique 
                        risks, goals and its own business context that 
                        requires understanding and collaboration to 
                        ensure successful delivery.</p>
                     <router-link to="/contact" class="tp-btn mt-10" >
                     Book an appointment</router-link>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script>
export default {
   name:'TeamDetailsArea'
}
</script>